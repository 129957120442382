import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import "../stylesheets/news.scss"

const NewsPage = ({ data }) => (
  <Layout>
    <SEO title="お知らせ" />
    <div className="newsPage-MainVisual">
      <div className="newsPage-MainVisual-headingWrapper">
        <h1 className="newsPage-MainVisual-heading">お知らせ</h1>
        <p className="newsPage-MainVisual-subHeading">News</p>
      </div>
    </div>
    <section className="newsPage-NewsSection">
      <div className="newsPage-NewsSection-inner">
        <ul className="newsPage-NewsSection-list">
          {data.allMicrocmsNews.edges.map(({ node }) => (
            <li key={node.id} className="newsPage-NewsSection-listItem">
              <Link
                to={`/news/${node.newsId}`}
                className="newsPage-NewsSection-listLink"
              >
                <p className="newsPage-NewsSection-listDate">
                  {node.created_at}
                </p>
                <p className="newsPage-NewsSection-listTitle">{node.title}</p>
              </Link>
            </li>
          ))}
        </ul>
        <nav className="newsPage-NewsSection-pageNav">
          <ul className="newsPage-NewsSection-pageList">
            {data.allMicrocmsNews.pageInfo.hasPreviousPage && (
              <li className="newsPage-NewsSection-pageArrow" data-type="prev">
                <Link
                  to={
                    data.allMicrocmsNews.pageInfo.currentPage === 2
                      ? `/news`
                      : `/news/page/${
                          data.allMicrocmsNews.pageInfo.currentPage - 1
                        }`
                  }
                  className="newsPage-NewsSection-pageLink"
                >
                  <StaticImage
                    src="../images/prev.png"
                    alt="前のページ"
                    className="newsPage-NewsSection-arrowIcon"
                  />
                </Link>
              </li>
            )}
            {Array.from({
              length: data.allMicrocmsNews.pageInfo.pageCount,
            }).map((_, i) => (
              <li className="newsPage-NewsSection-pageItem">
                <Link
                  to={i === 0 ? `/news` : `/news/page/${i + 1}`}
                  className="newsPage-NewsSection-pageLink"
                  data-current={
                    data.allMicrocmsNews.pageInfo.currentPage === i + 1
                  }
                >
                  {i + 1}
                </Link>
              </li>
            ))}
            {data.allMicrocmsNews.pageInfo.hasNextPage && (
              <li className="newsPage-NewsSection-pageArrow" data-type="next">
                <Link
                  to={`/news/page/${
                    data.allMicrocmsNews.pageInfo.currentPage + 1
                  }`}
                  className="newsPage-NewsSection-pageLink"
                >
                  <StaticImage
                    src="../images/next.png"
                    alt="次のページ"
                    className="newsPage-NewsSection-arrowIcon"
                  />
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </section>
  </Layout>
)

export default NewsPage

export const query = graphql`
  query($skip: Int, $limit: Int) {
    allMicrocmsNews(skip: $skip, limit: $limit) {
      edges {
        node {
          id
          created_at(formatString: "YYYY/MM/DD")
          newsId
          title
          contents
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`
